import React, { Fragment } from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled, { css } from "styled-components"
import { media } from "../styles/media"

const StyledDiv = styled.div`
  background: white;
  padding: 50px 10% 100px;
  h4 {
    font-size: 1.12em;
    margin-bottom: 20px;
    font-weight: bold;
  }
  .yearHeadline {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 50px;
    .years {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      width: 80%;
      color: grey;
      .yearStart {
        color: ${({ theme }) => theme.colors.primary.red};
        font-size: 1.12em;
      }
      .endStart {
        color: ${({ theme }) => theme.colors.primary.green};
        font-size: 1.12em;
      }
    }
    .gengo {
      width: 20%;
      text-align: right;
    }
    &:after {
      display: block;
      content: "";
      width: 100%;
      height: 2px;
      background: #dadada;
      border-radius: 3px;
      margin: 5px 0 10px;
    }
  }
  .yearLines {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 20px 0;
    h5 {
      width: 15%;
      font-size: 0.85em;
      font-weight: bold;
      letter-spacing: 0.12em;
    }
    span {
      width: 5%;
      font-size: 0.85em;
      color: grey;
    }
    p {
      width: 80%;
      line-height: 1.32em;
      font-size: 0.9em;
    }
  }
  ${() =>
    media.sp(css`
      .yearLines {
        flex-flow: row wrap;
        margin: 30px 0;
        h5 {
          width: 25%;
        }
        span {
          width: 75%;
        }
        p {
          width: 100%;
          padding-top: 0.62em;
        }
      }
    `)}
`

const FrancoisHistory = () => {
  const data = useStaticQuery(query)
  return (
    <StyledDiv id="sec3">
      <h4>{data.zoo.historyHeadline}</h4>
      {data.zoo.historySection.map(panda => (
        <Fragment key={panda.id}>
          <div className="yearHeadline">
            <div className="years">
              <span className="yearStart">{panda.startYear}</span>〜
              <span className="endStart">{panda.endYear}</span>
            </div>
            <span className="gengo">{panda.gengou}</span>
          </div>
          {panda.historyTimeline.map(penguin => (
            <div className="yearLines" key={penguin.id}>
              <h5>{penguin.yearHeadline}</h5>
              <span>/</span>
              <p
                dangerouslySetInnerHTML={{ __html: penguin.historyContents }}
              />
            </div>
          ))}
        </Fragment>
      ))}
    </StyledDiv>
  )
}

export const query = graphql`
  {
    zoo: strapiCompanyVision {
      historyHeadline
      historySection {
        startYear
        endYear
        gengou
        id
        historyTimeline {
          yearHeadline
          id
          historyContents
        }
      }
    }
  }
`

export default FrancoisHistory
