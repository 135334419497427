import React from "react"
import Layout from "../components/Layout"
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"
import PageHeaderMedia from "../components/PageHeaderMedia"
import styled, { css } from "styled-components"
import { media } from "../styles/media"
import { FiChevronRight } from "react-icons/fi"
import { IconContext } from "react-icons"
import FrancoisHistory from "../components/FrancoisHistoy"
import { Element, Link as ScrollLink } from "react-scroll"

const IconRight = () => (
  <IconContext.Provider value={{ size: "1.32em", color: "#E50012" }}>
    <FiChevronRight />
  </IconContext.Provider>
)

const StyledBackground = styled.div`
  width: 100%;
  height: 100vh;
  z-index: -11;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`
const StyledLinks = styled.div`
  background: white;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  padding: 50px 10% 50px;
  span {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    cursor: pointer;
    svg {
      margin-left: 0.62em;
    }
  }
  ${() =>
    media.sp(css`
      flex-flow: row wrap;
      justify-content: space-between;
      span {
        margin-bottom: 1.62em;
      }
    `)}
`
const StyledMessage = styled.div`
  background: white;
  padding: 50px 10%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  h2 {
    width: 40%;
    font-size: 1.62em;
  }
  p {
    width: 55%;
    font-size: 0.9em;
    line-height: 1.32em;
  }
  ${() =>
    media.sp(css`
      flex-flow: row wrap;
      padding-top: 30px;
      h2 {
        width: 100%;
        margin-bottom: 1.32em;
      }
      p {
        width: 100%;
      }
    `)}
`

const StyledVision = styled.div`
  background: transparent;
  padding: 100px 10%;
  .visionBox {
    background: rgba(228, 216, 185, 0.7);
    width: 70%;
    padding: 30px;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    h4 {
      width: 100%;
      font-weight: bold;
      margin-bottom: 0.62em;
    }
    ul li {
      font-size: 0.95em;
      margin: 0.62em 0;
    }
    &:nth-child(1) {
      margin-left: 30%;
      margin-bottom: 50px;
    }
  }
  ${() =>
    media.sp(css`
      .visionBox {
        width: 100%;
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    `)}
`
const StyledPresident = styled.div`
  background: white;
  padding: 100px 10%;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: space-between;
  .picture {
    width: 30%;
  }
  .messages {
    width: 65%;
    h3 {
      font-weight: bold;
      font-size: 1.32em;
    }
    p {
      margin: 15px 0;
      line-height: 1.32em;
      display: flex;
      flex-flow: column nowrap;
      span {
        width: 100%;
        text-align: right;
        font-weight: bold;
        margin-top: 1.32em;
      }
    }
  }
  ${() =>
    media.sp(css`
      padding: 50px 5%;
    `)}
`

const scrollAtt = {
  activeClass: "active",
  spy: true,
  smooth: true,
  offset: -70,
  duration: 500,
}

const CompanyVision = () => {
  const data = useStaticQuery(query)
  return (
    <Layout>
      <PageHeaderMedia
        fluid={data.rose.headerMediaPhoto.childImageSharp.fluid}
        pageTitles={data.rose.headerMediaTitle}
      />
      <StyledBackground>
        <Image
          fluid={data.rose.visionSecond.visionBackground.childImageSharp.fluid}
        />
      </StyledBackground>

      <StyledLinks>
        <ScrollLink
          activeClass="active"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
          to="sec1"
        >
          <span>
            企業理念・経営理念
            <IconRight />
          </span>
        </ScrollLink>
        <ScrollLink {...scrollAtt} to="sec2">
          <span>
            社長ごあいさつ
            <IconRight />
          </span>
        </ScrollLink>
        <ScrollLink {...scrollAtt} to="sec3">
          <span>
            沿革
            <IconRight />
          </span>
        </ScrollLink>
      </StyledLinks>

      <StyledMessage>
        <h2>{data.rose.visionPageFirst.visionBigTxt}</h2>
        <p
          dangerouslySetInnerHTML={{
            __html: data.rose.visionPageFirst.visionLongTxt,
          }}
        />
      </StyledMessage>

      <Element name="sec1">
        <StyledVision id="sec1">
          <div
            className="visionBox"
            dangerouslySetInnerHTML={{
              __html: data.rose.visionSecond.companyVision,
            }}
          />
          <div
            className="visionBox"
            dangerouslySetInnerHTML={{
              __html: data.rose.visionSecond.managementVision,
            }}
          />
        </StyledVision>
      </Element>
      <Element name="sec2" />
      <StyledPresident id="sec2">
        <div className="picture">
          <Image
            fluid={
              data.rose.presidentSection.presidentPicture.childImageSharp.fluid
            }
          />
        </div>
        <div className="messages">
          <h3>{data.rose.presidentSection.presidentHeadline}</h3>
          <div
            className="sayHello"
            dangerouslySetInnerHTML={{
              __html: data.rose.presidentSection.presidentSayHello,
            }}
          />
        </div>
      </StyledPresident>

      <Element name="sec3" />
      <FrancoisHistory />
    </Layout>
  )
}

export const query = graphql`
  {
    rose: strapiCompanyVision {
      headerMediaAlt
      headerMediaTitle
      headerMediaPhoto {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      visionPageFirst {
        visionBigTxt
        visionLongTxt
      }
      visionSecond {
        companyVision
        managementVision
        visionBackground {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      presidentSection {
        presidentHeadline
        presidentSayHello
        presidentPicture {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`

export default CompanyVision
